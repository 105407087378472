import {elements} from './base';
import nanomorph from 'nanomorph';
import html from 'nanohtml';

export const getInput = () => elements.autoInput.value;
export const clearInput = () => elements.autoInput.value = '';
export const clearList = () => elements.autoList.innerHTML = '';
export const disableInput = () => elements.autoInput.disabled = true;
export const enableInput = () => elements.autoInput.disabled = false;

export const highlightSelected = item => {
  const resultArr = Array.from(document.querySelectorAll('.search-wrapper-ul-auto li'));
  resultArr.forEach(el => el.classList.remove('li-active')); // Delete the old first
  item.classList.add('li-active'); // Add to current
};

function prettyPrint(value) {
  return value !== null ? value : '';
}

// privat: to be used in renderResults
const renderAdr = adr => {
  if (!adr.isSsr) {
    const { adressenavn, adressetekst, bokstav, id, nummer, postnummer, poststed } = adr;
    if(adressenavn !== null) {
      return html`
      <li class="list w-100 ba b--lighter-gray">
        <button data-itemid="${id}" class="w-100 bn transparent tl hover-bg-primary pa2" type="button">${adressenavn} ${prettyPrint(nummer)}${prettyPrint(bokstav)}, ${postnummer} ${poststed}</button>
      </li>`;
    } else {
      return html`
      <li class="list w-100 ba b--lighter-gray">
        <button data-itemid="${id}" class="w-100 bn transparent tl hover-bg-primary pa2" type="button">${adressetekst}, ${postnummer} ${poststed}</button>
      </li>`;
    }
  } else {
    const { stedsnavn, navnetype, id, kommunenavn } = adr;
    return html`
    <li class="list w-100 ba b--lighter-gray">
      <button data-itemid="${id}" class="w-100 bn transparent tl hover-bg-primary pa2" type="button">${stedsnavn}, ${navnetype} i ${kommunenavn}</button>
    </li>`;
  }
};

export const renderResults = adresser => {
  const ulList = html`
    <ul class="search-wrapper-ul-auto">
    </ul>
  `;
  
  adresser.forEach(adresee => ulList.appendChild(renderAdr(adresee)));
  nanomorph(elements.autoList, ulList);
}