import Search from './models/Search';
import Ssr from './models/Ssr';
import Kart from './models/Kart';
import * as searchView from './views/searchView';
import * as kartView from './views/kartView';
import {elements} from './views/base';
import '../css/style.css';

// First we get the viewport height and multiply it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// App state
const state = [];
//window.state = state; /* For testing, need to be delete when launching */
//console.log('App starts :)');

/******************2019-03-11**************************
 *** App controller
 ******************************************************/

state.kart = new Kart();
state.kart.getKart(); // With on click function
//state.kart.currentPos();

// Viser startside
window.onload = function() {
  document.getElementById('startside-btn-js').onclick = function() {
  document.getElementById('startside').style.display = 'none'
  };
};

/********************
* Search Controller
*/
state.search = new Search();
state.ssr = new Ssr();

const controlSearch = async() => {
  // 1) Get query from view
  const query = searchView.getInput();
  if(query) {
    // 2) New search object and add to the state
    //searchView.clearList();
    try {
      // 3) Seach for adresser
      await Promise.all([state.search.getResults(query), state.ssr.getResults(query)]);

      // 4) Render result on UI
      searchView.renderResults([...state.search.result, ...state.ssr.result]);
    } catch(err) {
      //alert('Wrong with search...');
      console.log(err);
    }
  }
};

// When user begin to type more than 3 letters
elements.autoInput.addEventListener('input', e => {
  if (elements.autoInput.value.length > 3) {
    controlSearch();
  } else {
    searchView.clearList();
  }
});

// When one of the results get clicked, should be showed in input box?
elements.wrapper.addEventListener('click', e => {

    console.log('click', e.target);
  if (e.target.matches('.search-wrapper-ul-auto button')) {
    const li = e.target;

    if(li.dataset.itemid) {
      // const liItem = document.querySelector(`[data-itemid="${li.dataset.itemid}"]`);
      state.res = [...state.search.result, ...state.ssr.result];
      state.item = state.res.find(i => i.id === li.dataset.itemid);
      // li.ssrId to do !!!
      searchView.highlightSelected(li);
      //elements.autoInput.value = li.innerText;
      searchSetMarker(); // setMarker() skal clearInput() og clearList();
      elements.autoInput.value = li.innerText;
      if (elements.sFormBtn.classList.contains("hide")) kartView.toggleHideBtn();  // for btn Lag nødplakat
    };
  }
});

// For å få geolocation fra bruker
elements.geolocate.addEventListener('click', e => {
  e.preventDefault(); // slipper å ha /#
  e.stopPropagation();
  state.kart.currentPos();
  searchView.clearList();
  searchView.clearInput();
  kartView.toggleHideBtn();  // for btn Lag nødplakat
});

function searchSetMarker() {            // Called in elements.wrapper click event
  // Take from old søk button
  if (state.item) {
    if (state.item.representasjonspunkt) {
      const {lat, lon} = state.item.representasjonspunkt;
      state.kart.setMarker([lat, lon]);
    } else if (state.item.isSsr) {
      const {lat, lon} = state.item;
      state.kart.setMarker([lat, lon]);
    }
  }
  searchView.clearList();
}

elements.searchForm.addEventListener('keydown', e => {
  if (e.keyCode == 13) {
    e.preventDefault();
    return false;
  }
});

// When the submit button is clicked, set a markør with the input value
elements.searchForm.addEventListener('submit', e => {  // choose the form element in header
  e.preventDefault();

  searchView.disableInput();

  kartView.renderForm(state.kart.latLng, state.kart.bbox);

  expandSection(elements.formDiv);

  kartView.toggleHideBtn();

  state.kart.disableMap();
});

elements.formDiv.addEventListener('click', e => {
  e.stopPropagation();
  if(e.target.matches('#avbryt') || e.target.matches('.arrow-up-white, .arrow-up-white *')) {
    if (elements.formDiv) {
      collapseSection(elements.formDiv);
      searchView.enableInput();
      state.kart.enableMap();
    };
  }
});

function expandSection(element) {
  // get the height of the element's inner content, regardless of its actual size
  var sectionHeight = element.scrollHeight;

  // have the element transition to the height of its inner content
  element.style.height = sectionHeight + 'px';

  // when the next css transition finishes (which should be the one we just triggered)
  element.addEventListener('transitionend', function heightEnd(e) {
    // remove this event listener so it only gets triggered once
    element.removeEventListener('transitionend', heightEnd);

    // remove "height" from the element's inline styles, so it can return to its initial value
    element.style.height = null;
  });
}

function collapseSection(element) {
  // get the height of the element's inner content, regardless of its actual size
  var sectionHeight = element.scrollHeight;

  // on the next frame (as soon as the previous style change has taken effect),
  // explicitly set the element's height to its current pixel height, so we
  // aren't transitioning out of 'auto'
  requestAnimationFrame(() => {
    element.style.height = sectionHeight + 'px';

    // on the next frame (as soon as the previous style change has taken effect),
    // have the element transition to height: 0
    requestAnimationFrame(() => {
      element.addEventListener('transitionend', function heightCollapseEnd(e) {
        // remove this event listener so it only gets triggered once
        element.removeEventListener('transitionend', heightCollapseEnd);

        kartView.toggleHideBtn();
      });

      element.style.height = 0 + 'px';
    });
  });
}
